@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@rocketfinancialcorp/rocket-ui/themes/default';
@import 'styles-next';
@import 'variables';
@import 'colors';
@import 'helpers';
@import './pages';
@import './components';

:root {
  --body-bg-color: #f7f7f7;
  --white-color: #ffffff;
  --black-color: #000000;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  font-family: $body-font;
  font-weight: 400;
  font-size: $font-size-xs;
  line-height: 1.25rem;
  letter-spacing: normal;
  background-color: var(--body-bg-color);
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

/* Google Autocomplete container */
.pac-container {
  z-index: 10000 !important;
}

/* Google Autocomplete container End */
.ng-dropdown-panel {
  z-index: $zIndexUserModalDropdown !important;
}

.ngb-dp-body {
  z-index: $zIndexUserModalDropdown !important;
}

.parent-id-field {
  margin-bottom: 1.25rem;
}

.range-select-field .parent-id-field {
  margin-bottom: 0 !important;
}

.ng2-pdf-viewer-container {
  overflow: initial !important;
}
