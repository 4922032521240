:root {
  --create-transaction-form-header-background: #175770;
  --transaction-amount-field-label-color: #f7f7f7;
  --transaction-amount-option-radio-input-border-color: #bababa;
}

.create-transaction-form {
  background: var(--white-color);

  &.top-segment {
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 0.5rem;
  }

  &.bottom-segment {
    margin-top: 0.5rem;
    border-radius: 0 0 1rem 1rem;
  }

  .amount-row {
    margin-bottom: 1rem;
  }

  .amount-options-row {
    margin-bottom: 1.25rem;
  }

  .create-transaction-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 1.75rem;
    z-index: 0;

    &::before {
      content: '';
      height: 12.5rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      border-radius: 16px 16px 0 0;
      background: linear-gradient(147.36deg, #2b9fad -17.64%, #30747c 77.06%, #175770 124.4%);
      transform: translateZ(0);
    }
  }

  .create-transaction-form-body {
    padding: 0 2.5rem 1.75rem;

    .financial-account-selection-title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.4375;
      color: var(--text-primary-color);
      margin-bottom: 0.5rem;
    }

    .financial-account-container {
      display: flex;
      flex-direction: column;
      min-height: 8.25rem;
      flex: 1;

      .empty-state-button {
        height: 8.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-radius: 8px;
        border: 1px dashed #bababa;
        background: linear-gradient(0deg, rgb(247 247 247 / 60%), rgb(247 247 247 / 60%));
      }

      .empty-state-button:not(:disabled):hover {
        border-color: var(--primary-color);
        cursor: pointer;
        background: rgb(43 159 173 / 20%);
      }

      .empty-state-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background: var(--primary-color);
        color: var(--white-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
      }

      .empty-state-button:disabled .empty-state-icon {
        background: var(--value-empty-color);
      }
    }
  }

  .transaction-amount-field-label {
    color: var(--transaction-amount-field-label-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.4375;
    text-align: center;
    margin-bottom: 0.25rem;
  }

  .transaction-amount-field-container {
    border-bottom: 1px solid var(--white-color);
    min-width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.25rem;

    .amount-preffix {
      font-size: 3rem;
      line-height: 1.354;
      font-weight: 700;
      color: var(--white-color);
    }

    .amount-suffix {
      font-size: 3rem;
      line-height: 1.354;
      font-weight: 700;
      color: var(--white-color);
    }

    &.disabled {
      opacity: 0.4;
    }
  }

  .transaction-amount-field-wrapper {
    display: inline-grid;

    &::after,
    .transaction-amount-field-input {
      width: auto;
      grid-area: 1 / 2;
      margin: 0;
      resize: none;
      background: none;
      appearance: none;
      border: none;
      font-size: 3rem;
      line-height: 1.354;
      font-weight: 700;
    }

    &::after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre-wrap;
    }

    .transaction-amount-field-input {
      color: var(--white-color);

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: rgb(255 255 255 /50%);
      }
    }

    &.transaction-amount-field-wrapper--percentage {
      &::after {
        content: attr(data-value);
      }

      .transaction-amount-field-input {
        text-align: center;
      }
    }
  }

  .transaction-amount-options-wrapper {
    border: 1px solid var(--default-border-color);
    background: var(--white-color);
    padding: 1rem 5rem 1.25rem;
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    .transaction-amount-options {
      display: flex;
      gap: 1.25rem;
      align-items: center;
    }

    .transaction-amount-options-title {
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.384;
      color: var(--text-primary-color);
      margin-bottom: 0.75rem;
      text-align: center;
    }

    .transaction-amount-option-radio-input-label {
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }

    .transaction-amount-option-item {
      display: flex;
      gap: 0.25rem;
      align-items: center;
    }

    .transaction-amount-option-item-value {
      font-size: 0.9375rem;
      line-height: 1.4;
      color: var(--text-primary-color);
      font-weight: 400;
    }

    .transaction-amount-option-radio-input {
      border: 2px solid var(--transaction-amount-option-radio-input-border-color);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      position: relative;
      padding: 0;
      flex-shrink: 0;
      margin: 0.25rem;
    }

    .transaction-amount-option-item:not(.disabled):hover .transaction-amount-option-radio-input,
    .transaction-amount-option-radio-input.checked {
      border-color: var(--primary);
    }

    .transaction-amount-option-item:not(.disabled):hover .transaction-amount-option-radio-input:after,
    .transaction-amount-option-radio-input.checked:after {
      width: 0.5rem;
      height: 0.5rem;
      content: '';
      position: absolute;
      left: 0.125rem;
      top: 0.125rem;
      background: var(--primary);
      border-radius: 50%;
    }

    .transaction-amount-option-item.disabled {
      .transaction-amount-option-radio-input,
      .transaction-amount-option-item-value {
        opacity: 0.4;
      }
    }

    .transaction-amount-option-item:not(.disabled) .transaction-amount-option-radio-input-label {
      cursor: pointer;
    }
  }

  .financial-account-selection-wrapper {
    display: flex;
    gap: 1.25rem;
  }

  .financial-account-selection-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MCI+CiAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMCIgcj0iMTkuMjUiIHN0cm9rZT0iIzRBNEE0QSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KICA8cGF0aCBmaWxsPSIjNEE0QTRBIiBkPSJNMTIgMjFoMTIuMTdsLTUuNTkgNS41OUwyMCAyOGw4LTgtOC04LTEuNDEgMS40MUwyNC4xNyAxOUgxMnYyWiIvPgo8L3N2Zz4K');
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    margin-top: 4.5625rem;
  }

  .financial-account-selection {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }

  .selected-financial-account {
    background: var(--container-accent-bg-color);
    border: 1px solid var(--default-border-color);
    padding: 1rem 1.25rem;
    display: flex;
    gap: 0.5rem;
    flex: 1;
    min-height: 11.5rem;
    border-radius: 8px;
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 0;
  }

  .edit-btn-wrapper {
    font-size: 1.5rem;
    width: 1.5rem;
    flex-shrink: 0;
    color: var(--primary-color);
  }

  .edit-btn:hover {
    background: var(--action-button-hover-bg-color);
    border-radius: 50%;
    outline: 2px solid var(--action-button-hover-bg-color);
  }

  .row {
    display: flex;
    gap: 0 1.25rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 600;
    flex-wrap: wrap;

    &.item-body-row-tall {
      min-height: 2.625rem;
    }

    .label {
      color: var(--text-secondary-color);
      width: 9rem;
      font-weight: 400;
      flex-shrink: 0;
    }

    .label-tooltip {
      display: inline;
      vertical-align: top;
    }

    .value {
      color: var(--value-color);
      flex: 1;
      min-width: 9rem;
      overflow-wrap: anywhere;
      word-break: normal;
    }

    .empty-value {
      color: var(--value-empty-color);
    }
  }

  .solutions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .rkt-icon {
      font-size: 1.5rem;
      flex-shrink: 0;
      color: var(--text-secondary-color);
    }

    .solution-item {
      display: flex;
      gap: 0.5rem;
      color: var(--text-primary-color);
      font-size: 0.875rem;
      line-height: 1.428;
      font-weight: 600;
      padding: 0.5rem 0.75rem;
      border-radius: 8px;
      border: 1px solid var(--default-border-color);
      min-width: 8.75rem;
      align-items: center;
    }

    .solution-item:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .solution-item:not(:disabled, .active):hover {
      border-color: var(--primary-color);
      cursor: pointer;
    }

    .solution-item.active {
      border-color: var(--primary-color);
      color: var(--primary-color);

      .rkt-icon {
        color: var(--primary-color);
      }
    }
  }

  .processing-priority-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .processing-priority-radio-input-label {
      display: inline-flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.4;
      color: var(--text-primary-color);
    }

    .processing-priority-radio-input {
      flex-shrink: 0;
      border: 2px solid var(--value-empty-color);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      position: relative;
      margin: 0.25rem 0.5rem 0.25rem 0.25rem;
    }

    .processing-priority-radio-input-label:not(.disabled):hover .processing-priority-radio-input,
    .processing-priority-radio-input.checked {
      border-color: var(--primary-color);
    }

    .processing-priority-radio-input-label:not(.disabled):hover .processing-priority-radio-input:after,
    .processing-priority-radio-input.checked:after {
      width: 0.5rem;
      height: 0.5rem;
      content: '';
      position: absolute;
      left: 0.125rem;
      top: 0.125rem;
      background: var(--primary-color);
      border-radius: 50%;
    }

    .processing-priority {
      flex: 1;
    }

    .processing-priority-item.disabled {
      opacity: 0.4;
    }

    .processing-priority-item:not(.disabled) .processing-priority-radio-input-label {
      cursor: pointer;
    }
  }

  .create-transaction-scheduler {
    padding-top: 1.25rem;
    display: flex;
    flex-direction: column;

    .scheduler-toggle-button {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      font-size: 0.9375rem;
      line-height: 1.4;
      color: var(--text-primary-color);
      font-weight: 400;

      .rkt-icon {
        color: var(--value-empty-color);
        font-size: 1.5rem;
      }

      &:hover,
      &.checked {
        .rkt-icon {
          color: var(--primary-color);
        }
      }

      &:disabled {
        opacity: 0.4;
      }
    }

    .move-money-scheduler-sent-count {
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
      color: var(--text-secondary-color);
      margin-top: 0.25rem;
    }

    .start-time-error {
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1.384;
      color: var(--error-color);
      margin-top: 0.25rem;
    }

    .create-transaction-scheduler-form-container {
      display: flex;
      gap: 5rem;
      margin-top: 0.75rem;
    }

    .create-transaction-scheduler-form {
      flex: 1;
    }

    .rkt-form-datepicker-field ngb-datepicker {
      transform: none !important;
      z-index: 2;
    }
  }

  .create-transxaction-attachments {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid var(--default-border-color);
  }

  .create-transaction-form-footer {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    border-top: 1px solid var(--default-border-color);
    display: flex;
    gap: 5rem;

    .create-transaction-note {
      flex: 1;
    }

    .buttons {
      flex: 1;
      gap: 1.5rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .create-transaction-note-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .create-transaction-note-label {
      color: var(--text-primary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
    }

    .create-transaction-note-textarea {
      background: var(--container-accent-bg-color);
      border: 1px solid var(--container-accent-bg-color);
      height: 6.25rem;
      border-radius: 4px;
      padding: 0.75rem 1.25rem;
      resize: none;
      color: var(--text-primary-color);
      font-size: 0.9375rem;
      line-height: 1.33;

      &:hover {
        border-color: var(--default-border-color);
      }

      &:focus {
        outline: none;
        border-color: var(--primary);
        background: var(--white-color);
      }
    }
  }

  .asterisk {
    color: var(--error-color);
  }
}

.create-transaction-financial-account-modal {
  --modal-content-width: 66.5rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    color: var(--text-primary-color);
    font-size: 1.125rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }

  .modal-buttons {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .list-page-controls {
    --table-filter-btn-border-color: #e2e3e4;

    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }

  .rkt-table-search {
    --input-border-color: #e2e3e4;
  }

  .two-line-col-item-first {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    margin-bottom: 0.25rem;
  }

  .two-line-col-item-second {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.417;
    color: var(--table-text-secondary-color);
  }

  .body-cell-accountHolderInfo {
    .two-line-col-item-first {
      font-weight: 600;
    }
  }

  .rkt-table .datatable-footer {
    border-top: none !important;
  }

  .ngx-datatable .datatable-footer .datatable-footer-inner {
    align-items: flex-end;
  }

  .rkt-table .datatable-footer .rkt-table-pager {
    border-top: 2px solid rgb(247 247 247 / 50%);
    padding-top: 1.125rem;
  }

  .no-pagination,
  .empty-list-loading {
    .rkt-table .datatable-footer .rkt-table-pager {
      display: none;
    }
  }

  .table-container {
    height: 25.9375rem;
  }

  .rkt-table .datatable-body-row-disabled .two-line-col-item-second {
    color: var(--table-disabled-row-text-color);
  }

  .header-cell-accountCategory .header-cell-inner,
  .body-cell-accountCategory .body-cell-inner {
    padding-right: 0;
  }
}

.create-transaction-review-modal {
  --modal-content-width: 56.125rem;

  .modal-body {
    padding: 1.75rem 2.25rem;
  }

  .modal-title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    color: var(--text-primary-color);
    margin-bottom: 0.5rem;
  }

  .transaction-id {
    text-align: center;
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--text-secondary-color);
    font-weight: 600;
  }

  .modal-icon {
    background-repeat: no-repeat;
    width: 8.25rem;
    height: 8.25rem;
    margin: 0 auto 0.75rem;
  }

  .modal-icon-success {
    background-image: url('/assets/images/speech-bubble-success.svg');
  }

  .modal-icon-scheduled {
    background-image: url('/assets/images/speech-bubble-scheduled.svg');
  }

  .financial-accounts-container {
    display: flex;
    gap: 1.25rem;
  }

  .financial-accounts-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCA0MCA0MCI+CiAgPGNpcmNsZSBjeD0iMjAiIGN5PSIyMCIgcj0iMjAiIGZpbGw9IiMyQjlGQUQiLz4KICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTIgMjFoMTIuMTdsLTUuNTkgNS41OUwyMCAyOGw4LTgtOC04LTEuNDEgMS40MUwyNC4xNyAxOUgxMnYyWiIvPgo8L3N2Zz4K');
    background-size: 2.5rem 2.5rem;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    margin-top: 4.5625rem;
  }

  .financial-account {
    min-width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .rows {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 0;
    background: var(--container-accent-bg-color);
    border-radius: 8px;
    padding: 1rem 1.25rem;
    flex: 1;
  }

  .row {
    display: flex;
    gap: 0 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 600;
    flex-wrap: wrap;

    &.row-with-top-border {
      border-top: 1px solid var(--default-border-color);
      padding-top: 0.5rem;
    }

    .label {
      color: var(--text-secondary-color);
      width: 8.125rem;
      font-weight: 400;
      flex-shrink: 0;
    }

    .value {
      color: var(--value-color);
      flex: 1;
      min-width: 11.875rem;
      overflow-wrap: anywhere;
      word-break: normal;
    }

    .empty-value {
      color: var(--value-empty-color);
    }

    .value-with-icon {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      .rkt-icon {
        font-size: 1rem;
        flex-shrink: 0;
      }
    }
  }

  .review-title {
    font-size: 1rem;
    line-height: 1.4375;
    color: var(--text-primary-color);
    font-weight: 600;
    margin-top: 0.375rem;
  }

  .review-header {
    position: relative;
    height: 2.5rem;
  }

  .multi-leg-title {
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--text-primary-color);
    font-weight: 600;
    display: flex;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.625rem 1rem;
    background: #ffb25733;
    border-radius: 0.5625rem;
    border: 1px solid #ffb257;
  }

  .multi-leg-title-icon {
    width: 1.25rem;
    height: 1.25rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iI0ZGQjI1NyIgZD0iTTIwIDEyYzAgMS41ODIyLS40NjkyIDMuMTI5LTEuMzQ4MiA0LjQ0NDYtLjg3OTEgMS4zMTU2LTIuMTI4NSAyLjM0MDktMy41OTAzIDIuOTQ2NC0xLjQ2MTguNjA1NS0zLjA3MDQuNzY0LTQuNjIyMi40NTUzLTEuNTUxODctLjMwODctMi45NzczMy0xLjA3MDYtNC4wOTYxNS0yLjE4OTQtMS4xMTg4Mi0xLjExODktMS44ODA3NS0yLjU0NDMtMi4xODk0My00LjA5NjItLjMwODY4LTEuNTUxOC0uMTUwMjUtMy4xNjA0LjQ1NTI1LTQuNjIyMTcuNjA1NS0xLjQ2MTgxIDEuNjMwODgtMi43MTEyNCAyLjk0NjQ3LTMuNTkwMjlDOC44NzEwMyA0LjQ2OTE5IDEwLjQxNzggNCAxMiA0YzIuMTIxNyAwIDQuMTU2Ni44NDI4NSA1LjY1NjkgMi4zNDMxNUMxOS4xNTcxIDcuODQzNDQgMjAgOS44NzgyNyAyMCAxMlptLTggMS42MTI4Yy0uMjkzNCAwLS41ODAyLjA4Ny0uODI0MS4yNXMtLjQzNC4zOTQ3LS41NDYzLjY2NTdjLS4xMTIzLjI3MTEtLjE0MTcuNTY5My0uMDg0NC44NTcuMDU3Mi4yODc4LjE5ODUuNTUyMS40MDU5Ljc1OTUuMjA3NS4yMDc1LjQ3MTguMzQ4OC43NTk1LjQwNi4yODc4LjA1NzIuNTg2LjAyNzkuODU3LS4wODQ0LjI3MTEtLjExMjMuNTAyOC0uMzAyNC42NjU3LS41NDYzLjE2My0uMjQ0LjI1LS41MzA4LjI1LS44MjQxIDAtLjM5MzQtLjE1NjItLjc3MDctLjQzNDQtMS4wNDg5LS4yNzgyLS4yNzgyLS42NTU1LS40MzQ1LTEuMDQ4OS0uNDM0NVptLTEuNDA4OC01LjMzMzMuMjM5MyA0LjM4NzJjLjAwNTMuMDk4OS4wNDgzLjE5MjEuMTIwMi4yNjAzLjA3MTkuMDY4My4xNjcyLjEwNjMuMjY2My4xMDYzaDEuNTY2N2MuMDk5LS4wMDAyLjE5NDEtLjAzODMuMjY1OS0uMTA2NS4wNzE3LS4wNjgyLjExNDctLjE2MTMuMTE5OS0uMjYwMWwuMjM5My00LjM4NzJjLjAwMjktLjA1MjUzLS4wMDUtLjEwNTA4LS4wMjMxLS4xNTQ0Ni0uMDE4Mi0uMDQ5MzgtLjA0NjItLjA5NDU1LS4wODIzLS4xMzI3NS0uMDM2Mi0uMDM4MTktLjA3OTgtLjA2ODYyLS4xMjgxLS4wODk0MS0uMDQ4My0uMDIwOC0uMTAwNC0uMDMxNTMtLjE1My0uMDMxNTVoLTIuMDQ0NmMtLjA1MjYtLjAwMDAxLS4xMDQ3LjAxMDcxLS4xNTMuMDMxNS0uMDQ4NC4wMjA3OS0uMDkxOS4wNTEyMS0uMTI4MS4wODk0MS0uMDM2Mi4wMzgyLS4wNjQyLjA4MzM4LS4wODIzLjEzMjc3LS4wMTgxLjA0OTM5LS4wMjYuMTAxOTYtLjAyMzEuMTU0NDlaIi8+Cjwvc3ZnPgo=');
    flex-shrink: 0;
    background-repeat: no-repeat;
  }

  .amount {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1.75rem;

    .label {
      font-size: 0.875rem;
      line-height: 1.428;
      color: var(--text-secondary-color);
      font-weight: 400;
      margin-bottom: 0.75rem;
    }

    .value {
      font-size: 2.25rem;
      line-height: 1.36;
      color: var(--text-primary-color);
      font-weight: 700;
    }
  }

  .financial-account-title {
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-primary-color);
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttons.editing {
    justify-content: center;
  }

  .schedule-preview {
    background: var(--secondary-color);
    border-radius: 10px;
    margin-bottom: 0.75rem;
    padding: 0.75rem 1.25rem;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .scheduled-for {
    display: flex;
    gap: 0.5rem;
    font-size: 0.9375rem;
    line-height: 1.4;
    font-weight: 600;
  }

  .schedule-icon {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMCAyMSI+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTYuMTkgMi4xNjdjLTIuNDg4IDAtNC41MjQgMi4wNDUtNC41MjQgNC41NDVzMi4wMzYgNC41NDUgNC41MjQgNC41NDUgNC41MjQtMi4wNDUgNC41MjQtNC41NDVTOC42NzggMi4xNjcgNi4xOSAyLjE2N1ptMCAxLjUxNWMxLjY1OSAwIDMuMDE2IDEuMzYzIDMuMDE2IDMuMDMgMCAxLjY2Ny0xLjM1NyAzLjAzLTMuMDE2IDMuMDMtMS42NTggMC0zLjAxNi0xLjM2My0zLjAxNi0zLjAzIDAtMS42NjcgMS4zNTgtMy4wMyAzLjAxNi0zLjAzWm03LjU0IDB2MS41MTVoLTEuNzA1Yy4xMjQuNDg1LjE5Ny45OS4xOTcgMS41MTUgMCAuODA0LS4xNiAxLjU3LS40NDMgMi4yNzNoNC4yMTNsLjAwMSA4LjMzM0g1LjQzNnYtNC41OTdhNS45NDMgNS45NDMgMCAwIDEtMS41MDgtLjM5NHY0Ljk5MWMwIC44MzcuNjc1IDEuNTE1IDEuNTA4IDEuNTE1aDEwLjU1NmMuODMzIDAgMS41MDgtLjY3OCAxLjUwOC0xLjUxNVY1LjE5N2gtMi4yNjJWMy42ODJIMTMuNzNabS02Ljg2LjkwOEw1LjU4NiA2Ljc4N2wxLjY2IDEuNjY4LjY3OC0uNzU4LTEuMTMtMS4wNi45MDMtMS41OTEtLjgyOS0uNDU2WiIvPgo8L3N2Zz4K');
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }

  .schedule-recurring {
    font-size: 1rem;
    line-height: 1.4375;
    font-weight: 600;
    margin-left: 1.75rem;
  }

  .scheduler-name {
    margin-top: 0.25rem;
    color: var(--text-primary-color);
    font-size: 1rem;
    line-height: 1.4375;
    font-weight: 600;
  }

  .notes {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1.5rem;

    .notes-label {
      color: var(--text-secondary-color);
      font-size: 0.8125rem;
      line-height: 1.384;
      font-weight: 400;
    }

    .notes-note {
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
      color: var(--text-primary-color);
    }
  }

  .btn-spinner {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
    }
  }

  .spinner-grow {
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--white);
    border-radius: 50%;
    animation: spinner-grow 0.5s linear infinite;
  }

  .rkt-button.loading {
    position: relative;
    opacity: 0.7;

    .btn-text {
      visibility: hidden;
    }
  }

  .additional-details-sections {
    display: flex;
    gap: 40px 80px;
    flex-wrap: wrap;
  }

  .additional-details-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4375;
    margin-bottom: 1.25rem;
  }

  .additional-details-inner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1 1 0;

    .details-item {
      display: flex;
      gap: 1rem;
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 400;
    }

    .details-label {
      width: 8.125rem;
      color: var(--text-secondary-color);
    }

    .details-value {
      flex: 1;
      min-width: 8.125rem;
      font-weight: 600;
    }

    .details-value-estimation {
      margin-top: 0.25rem;
      font-size: 0.75rem;
      line-height: 1.214;
      color: var(--text-tertiary-color);
    }

    .details-value-estimation .strong {
      font-weight: 600;
      color: var(--text-secondary-color);
    }
  }
}

.financial-account-address-form-modal {
  --preview-item-text-color: #4a4a4a;

  .description {
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.4;
    color: var(--preview-item-text-color);
    margin-bottom: 1.25rem;
  }

  .preview-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem 1.25rem;
    overflow: hidden;
  }

  .account-preview {
    border-left: 8px solid var(--primary);
    padding: 0.375rem 0 0.375rem 1.25rem;
    margin-bottom: 1.25rem;
  }

  .preview-content-item {
    display: flex;
    align-items: center;
    color: var(--preview-item-text-color);
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.428;
    min-width: 0;

    .rkt-icon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  .preview-content-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .preview-label {
    font-weight: 400;
  }

  .preview-content.preview-content-account-info {
    flex: 0 0 100%;
  }
}

.delivery-speed-form {
  display: flex;
  gap: 5rem;

  .delivery-speed-selection {
    flex: 1;
    position: relative;
    border-right: 1px solid var(--default-border-color);
    display: flex;
    min-width: 0;
  }

  .leg-fields {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .row {
      display: flex;
      gap: 0 0.75rem;
      font-size: 0.9375rem;
      line-height: 1.4;
      font-weight: 600;
      flex-wrap: wrap;

      &.row-with-top-border {
        border-top: 1px solid var(--default-border-color);
        padding-top: 0.5rem;
      }

      .label {
        color: var(--text-secondary-color);
        width: 8.125rem;
        font-weight: 400;
        flex-shrink: 0;
        height: 2.8125rem;
        align-items: center;
        display: flex;
      }

      .value {
        color: var(--value-color);
        flex: 1;
        min-width: 11.875rem;
      }

      .empty-value {
        color: var(--value-empty-color);
      }

      .value-with-icon {
        display: flex;
        gap: 0.25rem;
        align-items: center;

        .rkt-icon {
          font-size: 1rem;
          flex-shrink: 0;
        }
      }

      .rkt-form-label {
        display: none;
      }
    }

    .separator-line {
      margin-bottom: 2.25rem;
      padding-bottom: 2.25rem;
    }

    .funds-delivery-estimate-date {
      font-size: 1.5rem;
      line-height: 1.375;
      margin-bottom: 0.25rem;
      font-weight: 700;
      color: var(--primary-color);
    }

    .funds-delivery-estimate-details {
      color: var(--text-secondary-color);
      font-size: 0.875rem;
      line-height: 1.428;
      font-weight: 400;

      strong {
        color: var(--text-tertiary-color);
      }
    }
  }
}

@media (width <= 1152px) {
  .delivery-speed-form .leg-fields .row .label {
    width: 100%;
  }
}
