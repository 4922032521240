@import 'variables';

:root {
  --linx-icon-color: #4a4a4a;
}

.linx-registry-form .rkt-form-field {
  margin-bottom: 16px;
}

.linx-registry-form .rkt-form-field .rkt-form-label {
  font-weight: 700;
}

.linx-request-row .preview-edit:disabled {
  color: var(--linx-icon-color) !important;
}

.linx-request-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.5rem;
  gap: 1.5rem;
}

.add-document {
  display: flex;
  color: var(--primary);
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1.4375rem;
  cursor: pointer;
}

.debit-auth-forms {
  display: flex;
  gap: 1rem;

  .rkt-form {
    flex-grow: 1;

    .linx-debit-authorization .rkt-form-field {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--row-border-color);
    }

    .rkt-form-textarea-field-with-counter {
      height: 6.53rem;
      border: 1px solid #e2e3e4;
      border-radius: 8px;
      background: var(--white-color);
    }

    .rkt-form-textarea {
      background: var(--white-color);
    }
  }
}

.custom-input-section .rkt-form .rkt-form-input-field {
  align-items: center;
  gap: 1rem;
}

.manual-authorization {
  display: flex;
  gap: 1rem;
}

.rkt-form .linx-form-field-dropdown {
  display: block;
  margin: 0.5rem 0;
}

.linx-request-move-how {
  display: flex;
  gap: 1.25rem;

  .move-how-divider {
    border: 1px solid #e2e3e4;
  }
}

.linx-workflow-customer {
  .linx-customer-form-field .rkt-form-field {
    max-width: 50%;
  }
}

.rkt-form .linx-template-name,
.rkt-form .linx-workflow-title {
  display: block;
  margin-bottom: 1.25rem;
}

.rkt-form .linx-template-customer-radio-buttons,
.rkt-form .linx-workflow-customer-radio-buttons {
  display: block;
  margin: 1.25rem 0;

  .rkt-form-label {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.438rem;
    padding-left: 0.5rem;
    border-left: 0.5rem solid $ux-teal-green;
    margin-bottom: 1rem;
  }
}

.linx-template-form .rkt-form-field {
  margin-bottom: 16px;
}

.linx-template-name .rkt-form-field {
  margin-top: 1rem;
}

.linx-checkbox-enhance-fields .rkt-form-checkbox-group-field {
  display: flex;
  flex-flow: row wrap;
}

.enhance-profile {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  .enhance-profile-details-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.875rem;

    .profile-dot {
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 50%;
      background: #4a4a4a;
    }
  }
}

.enhance-profile-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-left: 2.75rem;
  padding-top: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.enhance-profile-title {
  display: flex;
  gap: 4px;
  align-items: center;

  .rkt-icon {
    font-size: 1.5rem;
  }
}

.enhance-profile-options {
  padding: 0.75rem 1.25rem;
  border-radius: 0.375rem;
  background: var(--container-accent-bg-color);
}

.linx-checkbox-group .rkt-form-field-group {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .rkt-form-label {
    font-weight: 700;
  }
}

.registry-form-preview {
  background: var(--container-accent-bg-color);
  border-radius: 0.375rem;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  .documents-preview {
    display: flex;
    flex-direction: column;
  }

  .preview-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 0.75rem;
    overflow: hidden;
  }

  .preview-content-item {
    display: flex;
    align-items: center;
    color: var(--text-secondary-color);
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1.714;
    min-width: 0;

    .rkt-icon {
      font-size: 1.5rem;
      color: var(--text-secondary-color);
      margin-right: 0.5rem;
    }
  }

  .preview-edit {
    font-size: 1.5rem;
    color: var(--primary);

    &:hover {
      border-radius: 50%;
      background: var(--action-button-hover-bg-color);
      outline: 4px solid var(--action-button-hover-bg-color);
    }
  }
}
