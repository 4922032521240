.notes-feed {
  background: var(--container-accent-bg-color);
  border-radius: 0.375rem;
  padding: 0.875rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.notes-feed-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.note-feed-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .author {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-primary-color);
  }

  .date {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.428;
    color: var(--text-secondary-color);
  }
}

.note-feed-item-content {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.428;
  color: var(--text-primary-color);
}

.attachments-item-files {
  .rkt-carousel-items-container {
    gap: 0 !important;
  }

  .rkt-carousel-offset-container {
    display: none;
  }
}

.note-feed-item-attachments {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  .file-item {
    --file-item-border-color: var(--default-border-color);

    cursor: pointer;
    width: 11.5rem;
    margin: 0 auto;

    &:hover {
      --file-item-border-color: var(--primary-color);
    }
  }

  .file-name {
    background: var(--white-color);
    border: 1px solid var(--file-item-border-color);
    border-radius: 8px 8px 0 0;
    padding: 0.5rem 1.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.416;
    color: var(--text-primary-color);
    gap: 0.25rem;

    .attachment-icon {
      flex-shrink: 0;
      font-size: 1.25rem;
      color: var(--text-secondary-color);
    }

    .file-name-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .file-image {
    width: 100%;
    height: 7.5rem;
    padding: 0.75rem 1.25rem 0;
    border-radius: 0 0 8px 8px;
    position: relative;
    overflow: hidden;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: var(--file-item-border-color);
    display: flex;
    background: #f7f7f7;

    .banner-text {
      position: absolute;
      top: 3.125rem;
      left: 0;
      color: var(--text-secondary-color);
      font-size: 0.875rem;
      line-height: 1.428;
      right: 0;
      text-align: center;
    }

    .file-image-preview {
      margin: 0 auto;
    }
  }
}

.attachments-item {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.attachments-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .attachments-count {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.428;
    color: var(--text-primary-color);
  }

  .view-type-switcher {
    padding: 2px;
    display: inline-flex;
    background: var(--default-border-color);
    border-radius: 4px;
    overflow: hidden;
    user-select: none;

    .type-switch-btn {
      padding: 0.125rem 0.25rem;
      color: var(--text-tertiary-color);
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      transition: all 200ms;

      &::after {
        background: var(--primary-color);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        transition: all 200ms cubic-bezier(0.77, 0, 0.175, 1);
        border-radius: 4px;
      }

      &.btn-left::after {
        left: calc(100% + 2px);
        right: -50%;
      }

      &.btn-left.active::after {
        left: 0;
        right: 0;
      }

      &.btn-right {
        margin-left: 2px;
      }

      &.btn-right::after {
        right: calc(100% + 2px);
        left: -50%;
      }

      &.btn-right.active::after {
        right: 0;
        left: 0;
      }

      .rkt-icon {
        color: var(--text-tertiary-color);
        z-index: 1;
        position: relative;
        transition: color 200ms;
        font-size: 1.25rem;
      }

      &.active {
        .rkt-icon {
          color: var(--white-color);
        }
      }
    }

    &:hover .type-switch-btn:not(.active) .rkt-icon {
      color: var(--primary-color);
    }
  }
}

.notes-feed-add-form {
  .note-attention {
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.36;
    color: var(--text-secondary-color);
    margin-top: 0.25rem;
    margin-bottom: 0.75rem;
  }

  .attachments-title {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.428;
    color: var(--text-primary-color);
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .add-attachment-btn {
    font-size: 1.25rem;
    color: var(--primary-color);

    &:hover {
      background: var(--action-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--action-button-hover-bg-color);
    }
  }

  .add-notes-buttons {
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  .attachments-list {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }

  .attachments-list-item {
    border: 1px solid var(--default-border-color);
    border-radius: 6px;
    padding: 0.6875rem 1.1875rem;
    display: flex;
    gap: 0.75rem;
  }

  .attachment-info {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    min-width: 0;

    .attachment-icon {
      flex-shrink: 0;
      font-size: 1.25rem;
      color: var(--text-secondary-color);
    }

    .attachment-name {
      white-space: nowrap;
      flex: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 0.9375rem;
      line-height: 1.4;
      color: var(--text-primary-color);
    }
  }

  .attachment-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .attachment-item-button {
    font-size: 1.25rem;
    color: var(--text-secondary-color);
    flex-shrink: 0;

    &:hover {
      background: var(--action-button-hover-bg-color);
      border-radius: 50%;
      outline: 2px solid var(--action-button-hover-bg-color);
    }
  }

  .attachment-item-button-delete {
    color: var(--error-color);
  }
}

.notes-feed-attachments-list {
  .attachment-extension .body-cell-name .body-cell-value {
    background-size: 1.25rem;
    background-repeat: no-repeat;
    padding-left: 1.75rem;
  }

  .attachment-extension:not(.attachment-extension-pdf) .body-cell-name .body-cell-value {
    background-image: url('/assets/images/attachment-img.svg');
  }

  .attachment-extension.attachment-extension-pdf .body-cell-name .body-cell-value {
    background-image: url('/assets/images/attachment-pdf.svg');
  }
}

.notes-refresh-btn {
  font-size: 1.25rem;
  color: var(--primary-color);
  padding: 0.25rem;
  margin: -0.25rem 0 -0.25rem 0.25rem;
  border-radius: 50%;
  border: none;

  &:not(:disabled):hover {
    background: var(--action-button-hover-bg-color);
  }
}
