:root {
  --datepicker-background-color: #ffffff;
}

.rkt-form-datepicker-field {
  ngb-datepicker {
    z-index: 1;
    background: var(--datepicker-background-color);
    top: 100% !important;
    margin-top: 0.25rem;

    &.ngb-dp-body {
      background: white;
      border-radius: 6px;
      width: 357px;
    }

    .ngb-dp-header {
      padding-top: 20px;
    }

    .ngb-dp-weekdays {
      border-bottom: none;
    }

    .ngb-dp-weekday {
      width: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4375;
      text-align: center;
      color: #4a4a4a;
      font-style: normal;
      height: 23px;
      margin-bottom: 2px;
    }

    .ngb-dp-month {
      padding: 0 20px 20px;
      width: 100%;
    }

    .ngb-dp-weekdays,
    .ngb-dp-week {
      margin-bottom: 6px;
      justify-content: space-between;
    }

    .ngb-dp-day {
      width: 40px;
      height: 40px;
    }

    .ngb-dp-month:first-child .ngb-dp-week {
      padding-left: 0;
    }

    .ngb-dp-month:last-child .ngb-dp-week {
      padding-right: 0;
    }

    .custom-day {
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 15px;
      display: flex;
      line-height: 1.364;
      border-radius: 5px;
      height: 100%;
      background: #f7f7f7;
      color: #111111;
    }

    .custom-day.disabled {
      background: rgb(247 247 247 / 30%);
      color: rgba(#bababa, 0.5);
    }

    .custom-day:not(.disabled):hover {
      background: #2b9fad;
      color: white;
    }

    .custom-day.selected {
      background: #2b9fad;
      border-radius: 5px;
      color: white;
    }

    .custom-day:not(.disabled).text-muted {
      background: rgb(247 247 247 / 50%);
      color: #bababa;
    }

    .custom-day:not(.disabled).text-muted:hover {
      background: #2b9fad;
      color: white;
    }

    .ngb-dp-navigation-select {
      flex: 0 0 15.4375rem;
      justify-content: space-between;
    }

    ngb-datepicker-navigation {
      margin-bottom: 6px;
      padding: 0 20px;
    }

    .ngb-dp-navigation-select .custom-select {
      padding: 11px 3px 11px 11px;
      width: 120px;
      height: 44px;
      background: #ffffff;
      border: 1px solid #e2e3e4;
      border-radius: 6px;
      flex: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 1.364;
      color: #111111;
      appearance: none;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMSAyMCI+CiAgPHBhdGggZmlsbD0iI0JBQkFCQSIgZD0ibTMuOTM4NTYgNi41MjI0Mi41OTY0MS0uNTg2NzZjLjA2ODIzLS4wNjU4LjE1OTk2LS4xMDI2NS4yNTU1LS4xMDI2NS4wOTU1NCAwIC4xODcyNy4wMzY4NS4yNTU1LjEwMjY1bDUuNDU0NTMgNS4zNTQ4NCA1LjQ1NDYtNS4zNTQ4NGMuMDY4Mi0uMDY1OC4xNTk5LS4xMDI2NS4yNTU1LS4xMDI2NS4wOTU1IDAgLjE4NzMuMDM2ODUuMjU1NS4xMDI2NWwuNTk2NC41ODY3NmMuMDY2OS4wNjcxMy4xMDQzLjE1NzM4LjEwNDMuMjUxMzcgMCAuMDk0LS4wMzc0LjE4NDI1LS4xMDQzLjI1MTM4bC02LjMwNzIgNi4yMDUyM2MtLjA2ODIuMDY1OC0uMTYuMTAyNi0uMjU1NS4xMDI2cy0uMTg3My0uMDM2OC0uMjU1NS0uMTAyNkwzLjkzNzEgNy4wMjUxN2MtLjA2NjY4LS4wNjczMi0uMTAzODgtLjE1NzY4LS4xMDM2LS4yNTE2Ny4wMDAyNy0uMDk0LjAzNzk5LS4xODQxNC4xMDUwNi0uMjUxMDhaIi8+Cjwvc3ZnPgo=');
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 20px;
      background-position:
        right 0.7em top 50%,
        0 0;
    }

    .ngb-dp-navigation-select .custom-select:focus {
      outline: none;
      border-color: var(--primary);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMSAyMCI+CiAgPHBhdGggZmlsbD0iIzJiOWZhZCIgZD0ibTMuOTM4NTYgNi41MjI0Mi41OTY0MS0uNTg2NzZjLjA2ODIzLS4wNjU4LjE1OTk2LS4xMDI2NS4yNTU1LS4xMDI2NS4wOTU1NCAwIC4xODcyNy4wMzY4NS4yNTU1LjEwMjY1bDUuNDU0NTMgNS4zNTQ4NCA1LjQ1NDYtNS4zNTQ4NGMuMDY4Mi0uMDY1OC4xNTk5LS4xMDI2NS4yNTU1LS4xMDI2NS4wOTU1IDAgLjE4NzMuMDM2ODUuMjU1NS4xMDI2NWwuNTk2NC41ODY3NmMuMDY2OS4wNjcxMy4xMDQzLjE1NzM4LjEwNDMuMjUxMzcgMCAuMDk0LS4wMzc0LjE4NDI1LS4xMDQzLjI1MTM4bC02LjMwNzIgNi4yMDUyM2MtLjA2ODIuMDY1OC0uMTYuMTAyNi0uMjU1NS4xMDI2cy0uMTg3My0uMDM2OC0uMjU1NS0uMTAyNkwzLjkzNzEgNy4wMjUxN2MtLjA2NjY4LS4wNjczMi0uMTAzODgtLjE1NzY4LS4xMDM2LS4yNTE2Ny4wMDAyNy0uMDk0LjAzNzk5LS4xODQxNC4xMDUwNi0uMjUxMDhaIi8+Cjwvc3ZnPgo=');
    }

    .ngb-dp-arrow {
      width: 24px;
      align-items: center;
      height: 24px;
      justify-content: center;
    }

    .ngb-dp-arrow:not(.right) {
      margin-right: 8px;
    }

    .ngb-dp-arrow.right {
      justify-content: center;
      margin-left: 8px;

      .ngb-dp-arrow-btn .ngb-dp-navigation-chevron {
        transform: rotate(180deg);
        margin: 0;
      }
    }

    .ngb-dp-arrow-btn {
      margin: 0;
      padding: 0;
      color: #4a4a4a;
      display: flex;

      .ngb-dp-navigation-chevron {
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        transform: none;
        margin: 0;
        width: 24px;
        height: 24px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMjAgMTAuOTk5M0g3LjgzMDVsNS41ODk3LTUuNTg5MzdMMTIuMDAwNiA0IDQgMTJsOC4wMDA2IDggMS40MS0xLjQwOTktNS41ODAxLTUuNTkwNUgyMHYtMi4wMDAzWiIvPgo8L3N2Zz4K');
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    .ngb-dp-arrow-btn:not(:disabled):hover,
    .ngb-dp-arrow-btn:not(:disabled):active,
    .ngb-dp-arrow-btn:not(:disabled):focus {
      outline: none;

      .ngb-dp-navigation-chevron {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbD0iIzJiOWZhZCIgZD0iTTIwIDEwLjk5OTNINy44MzA1bDUuNTg5Ny01LjU4OTM3TDEyLjAwMDYgNCA0IDEybDguMDAwNiA4IDEuNDEtMS40MDk5LTUuNTgwMS01LjU5MDVIMjB2LTIuMDAwM1oiLz4KPC9zdmc+Cg==');
      }
    }
  }
}

.calendar-toggle-button {
  font-size: 24px;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #111111;
  pointer-events: none;
}

.date-selector:not(:disabled):hover {
  & + .calendar-toggle-button {
    color: var(--primary);
  }
}

.date-selector:disabled + .calendar-toggle-button {
  opacity: 0.4;
}
