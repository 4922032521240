.rkt-button {
  display: flex;
  border: none;
  border-radius: 8px;
  font-size: var(--btn-font-size);
  line-height: 1.4;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  &.disabled {
    opacity: 0.3;
  }

  &:focus-visible {
    outline: 2px solid #105861;
  }
}

.rkt-button:not(.disabled) {
  cursor: pointer;
}

.rkt-button-primary {
  padding: 12px 20px;
  font-weight: 600;
  background: var(--btn-primary-bg);
  color: var(--btn-primary-color);

  &:not(.disabled):hover {
    background: var(--btn-primary-hover-bg);
  }
}

.rkt-button-secondary {
  padding: 12px 20px;
  font-weight: 600;
  background: var(--btn-secondary-bg);
  color: var(--btn-secondary-color);

  &:not(.disabled):hover {
    background: var(--btn-secondary-hover-bg);
  }
}

.rkt-button-tertiary {
  padding: 12px;
  font-weight: 400;
  background: var(--btn-tertiary-bg);
  color: var(--btn-tertiary-color);

  &:not(.disabled):hover {
    background: var(--btn-tertiary-hover-bg);
    color: var(--btn-tertiary-hover-color);
  }
}

.rkt-button-link {
  padding: 8px 12px;
  font-weight: 400;
  background: var(--btn-link-bg);
  color: var(--btn-link-color);

  &:not(.disabled):hover {
    background: var(--btn-link-hover-bg);
    color: var(--btn-link-hover-color);
  }
}

.rkt-button-danger {
  padding: 8px 12px;
  font-weight: 400;
  background: var(--btn-danger-bg);
  color: var(--btn-danger-color);

  &:not(.disabled):hover {
    background: var(--btn-danger-hover-bg);
    color: var(--btn-danger-hover-color);
  }
}
