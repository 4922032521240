@import 'variables';
@import 'reboot';
@import 'root';
@import 'buttons';
@import 'table';
@import 'form';
@import 'table-search';
@import 'datepicker';
@import 'timepicker';

$white: #ffffff;
$dim-gray: #707070;
$dark-gray: #333333;
$light-gray: #f7f7f7;
$light-blue: #f5faff;
$gray: #e2e3e4;
$placeholder-bg: #ced4da99;
$row-hover-bg: #e7e8e9;
$sort-btn-color: #939b9a;
$pager-pages-color: #2e2b57;
$header-cell-color: #001411;
$green: green;

body {
  font-family: $body-font;
  position: relative;
}

.rkt-icon {
  display: flex;
  max-width: 1em;
  height: 1em;
}

.hidden,
[hidden] {
  display: none !important;
}
